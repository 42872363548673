import React, {FunctionComponent} from "react";
import RadioButtons from "../../components/RadioButtons";

import HelperStyles from "../../styles/helper.module.scss";
import NormalCheckBox from "../../components/NormalCheckBox";
import {handleChange} from "../../helpers";
import {EnterJobStep} from "./EnterJobData";

const FourthStep: FunctionComponent<EnterJobStep> = ({ data, setData }) => {


  return (
    <>
      <div className={HelperStyles.flex}>
        <div className={HelperStyles.w25}>
          <h2 className={HelperStyles.textLeft}>Datenschutz</h2>
        </div>
      </div>
      <div className={`${HelperStyles.flex} ${HelperStyles.mb2}`}>
        <div className={HelperStyles.wfull}>
          <NormalCheckBox
              onInputChange={handleChange(setData)}
              value={parseInt(data.datenschutz) ? 0 : 1}
              checked={parseInt(data.datenschutz) ? true : false}
              title=""
              required={true}
              name="datenschutz"
              label={
                "Ich erkläre mich mit der <a target='blank' rel='nofollow noopener' href='https://www.jugendservice.at/datenschutz/'>Datenschutzerklärung</a> einverstanden"
              }
              errorMessage={"Bitte akzeptieren Sie die Datenschutzerklärung"}
          />
        </div>
      </div>
    </>
  );
};

export default FourthStep;
